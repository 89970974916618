import React from "react";

import { graphql } from "gatsby";
import Page from "../Layout/Page";
import { Artwork, QueryResult } from "../utils/types";
import getGatsbyImage from "../utils/getGatsbyImage";
import { getSrc } from "gatsby-plugin-image";
import { ImageList, ImageListItem } from "@mui/material";
import { Helmet } from "react-helmet";
import { getSeoLinks, getSeoMetas } from "../components/SeoHelper";

export const query = graphql`
  {
    images: allFile(filter: { relativeDirectory: { eq: "drawings" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
            original {
              src
            }
          }
        }
      }
    }
  }
`;
type NestedQuery = {
  images: {
    edges: {
      node: {
        childImageSharp: {
          gatsbyImageData: any;
          original: {
            src: string;
          };
        };
      };
    }[];
  };
};

function getArtworksFromData(data: NestedQuery): Artwork[] {
  return data.images.edges.map((edge, index) => ({
    src: getSrc(edge.node.childImageSharp.gatsbyImageData) || "",
    title: `Drawing number ${index}`,
  }));
}

const Drawings: React.VFC = ({ data }: any) => {
  const artworks = getArtworksFromData(data);
  return (
    <Page title={"drawings"}>
      <Helmet
        title="Drawings"
        titleTemplate="%s · Lisa Angrino"
        meta={getSeoMetas({
          title: "Drawings",
          path: "drawings",
          description:
            "Drawings by Lisa Angrino, a visual artist and philosopher from Colombia.",
        })}
        link={getSeoLinks({ path: "drawings" })}
      />
      <ImageList
        variant="masonry"
        sx={{
          columnCount: {
            xs: "1 !important",
            sm: "2 !important",
            md: "3 !important",
            lg: "3 !important",
            xl: "3 !important",
          },
        }}
        gap={8}
      >
        {artworks.map((item: Artwork) => (
          <ImageListItem key={item.src}>
            <img src={item.src} />
          </ImageListItem>
        ))}
      </ImageList>
    </Page>
  );
};

export default Drawings;
