import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";

import Theme, { myTheme } from "../utils/Theme";

import Header, { NAV_BAR_HEIGHT } from "./Header";
import Footer from "./Footer";

const Page: React.FC<{ title: string }> = ({ children, title }) => {
  return (
    <Theme>
      <Box
        height="100vh"
        display={"flex"}
        flexDirection="column"
        justifyContent={"space-between"}
      >
        <Header />
        <Container sx={{ paddingBottom: myTheme.spacing(3) }}>
          <Stack marginTop={`calc(${NAV_BAR_HEIGHT} +  0.35em + 8px)`}>
            <Box flex="1" display="flex" justifyContent="center">
              <Typography
                variant="h1"
                component="span"
                gutterBottom
                fontSize={{ xs: "1.5em", sm: "3em", lg: "4em" }}
              >
                {title}
              </Typography>
            </Box>
            <Box>{children}</Box>
          </Stack>
        </Container>
        <Footer contactSection />
      </Box>
    </Theme>
  );
};

export default Page;
